import dynamic from "next/dynamic";
import Link from "next/link";

const MetaTags = dynamic(() => import("@/common/MetaTags"));

export default function Custom404() {
  return (
    <>
      <MetaTags />
      <section className="min-h-50 flex items-center justify-center pt-28 md:pt-10 pb-28 md:pb-10 md:min-h-50 lg:min-h-50 xl:min-h-75 relative">
        <div className="max-w-4xl mx-auto text-center w-full">
          <h1 className="text-h1 font-bold text-center">400 Error</h1>
          <div className="text-center">
            <Link
              href="/"
              className="inline-block mt-8 uppercase tracking-semi font-bold text-xs border-2 border-theme hover:bg-theme py-2 px-4 rounded-lg text-theme hover:text-white"
            >
              Go Home
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
